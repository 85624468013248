<template>
  <div class="w-100">
    <p
      v-if="label"
      class="input__label"
      :class="{ disabled }"
    >
      {{ labelText }}
    </p>

    <div class="d-flex gap-xxs input-copy">
      <VInput
        :name="name"
        with-copy
        :disabled="disabled"
        :required="required"
        :pattern="pattern"
        @enter="copyInputField"
        @copyField="copyInputField"
      >
        <Validation v-if="required" for="required"/>
        <Validation v-if="pattern" for="pattern" :key-text="patternKey"/>
      </VInput>

      <VInput
        v-if="!isMobile"
        v-model="modelCopy"
        disabled
      />
    </div>
  </div>
</template>

<script>
import Validation from '@/components/Form/Validation'
import VInput from '@/components/Form/Vinput/VInput'

import { PATTERN_LIST } from '@/const/validation'
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
  name: 'InputCopy',
  components: { Validation, VInput },
  inject: {
    formData: {
      from: 'formData',
      default: null
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    patternKey: {
      type: String,
      default: ''
    },
    modelCopy: {
      type: [String, Number, null],
      required: true
    }
  },
  computed: {
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),

    pattern () {
      if (!this.patternKey) return null

      return PATTERN_LIST[this.patternKey]
    },
    labelText () {
      return this.required ? this.label + ' *' : this.label
    }
  },
  methods: {
    copyInputField () {
      if (this.modelCopy || this.modelCopy === 0) {
        Vue.set(this.formData, this.name, this.modelCopy)
      }
    }
  }
}
</script>
