import { PATTERN_LIST } from '@/const/validation'
import { mapGetters, mapState } from 'vuex'
import { POSITION_FORMULAS } from '@/utils/production/position/formula'
import Vue from 'vue'

const mixinPositionForm = {
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    hasProbes: {
      type: Boolean,
      default: false
    },
    isCatalog: {
      type: Boolean,
      default: false
    },
    isTemptate: {
      type: Boolean,
      default: false
    },
    shapeCatalog: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    pattern: PATTERN_LIST
  }),
  computed: {
    ...mapGetters('records', {
      propertiesAll: 'propertiesAll',
      shapeAll: 'shapeAll',
      shapeType2: 'shapeType2'
    }),
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),

    // Используется в mixinCoatingAndColorOptionsFiltered
    currentCoating () { return this.formData.coating },
    currentColor () { return this.formData.color },

    // для calcStiffness
    position () {
      return this.formData
    },
    // Индексы
    calcIndex1 () {
      if (this.visibleDOutput) {
        return POSITION_FORMULAS.indexByD(this.formData.d_wire, this.formData.d_outer_1)
      }
      if (this.visibleDInner) {
        return POSITION_FORMULAS.indexByD(this.formData.d_wire, this.formData.d_inner, true)
      }
      return ''
    },
    calcIndex2 () {
      if (!this.isType127) return ''
      return POSITION_FORMULAS.indexByD(this.formData.d_wire, this.formData.d_outer_2)
    },
    // Допуск по наружному/внутреннему диаметру
    calcDiameterLimit () {
      if (this.isType4) return ''
      return POSITION_FORMULAS.diameterLimit(this.formData.d_wire, this.calcIndex1)
    },
    // Допуск по наружному диаметру 2
    calcDOuterLimit2 () {
      if (!this.isType127) return ''
      return POSITION_FORMULAS.diameterLimit(this.formData.d_wire, this.calcIndex2)
    },
    // Допуск по длине тела
    calcBodyLengthLimit () {
      if (!this.notType48) return ''
      if (this.isType2) {
        return POSITION_FORMULAS.bodyLengthLimitForType2({
          dWire: this.formData.d_wire,
          turns: this.formData.number_turns
        })
      }
      if (this.isType359) {
        return POSITION_FORMULAS.bodyLengthLimitByLength(this.formData.body_length)
      }
      return POSITION_FORMULAS.bodyLengthLimit({
        dWire: this.formData.d_wire,
        turns: this.formData.number_turns,
        step: this.formData.step
      })
    },
    // Масса 1 пружины
    calcWeight () {
      return POSITION_FORMULAS.weight({
        dWire: this.formData.d_wire,
        sweepLength: this.formData.sweep_length,
        density: this.formData?.material?.density
      })
    },
    // Длина развертки
    calcSweepLength () {
      if (this.isType2) {
        return POSITION_FORMULAS.sweepLengthByHookType({
          dWire: this.formData.d_wire,
          dOuter: this.formData.d_outer_1,
          turns: this.formData.number_turns,
          ht1Id: this.formData?.hook_type_1?.id,
          ht2Id: this.formData?.hook_type_2?.id,
          hl1: this.formData?.hook_length_1,
          hl2: this.formData?.hook_length_2,
          hg1: this.formData?.hook_gap_1,
          hg2: this.formData?.hook_gap_2
        })
      }
      if (this.isType3) {
        return POSITION_FORMULAS.sweepLengthByTapLengthAndDOuter({
          dWire: this.formData.d_wire,
          dOuter: this.formData.d_outer_1,
          turns: this.formData.number_turns,
          tapLength1: this.formData.tap_length_1,
          tapLength2: this.formData.tap_length_2
        })
      }
      if (this.isType5) {
        return POSITION_FORMULAS.sweepLengthByTapLengthAndDInner({
          dWire: this.formData.d_wire,
          dInner: this.formData.d_inner,
          turns: this.formData.number_turns,
          tapLength1: this.formData.tap_length_1,
          tapLength2: this.formData.tap_length_2
        })
      }
      if (this.isType9) {
        return POSITION_FORMULAS.sweepLengthByD({
          dWire: this.formData.d_wire,
          diameter: this.formData.d_inner,
          turns: this.formData.number_turns,
          isInner: true
        })
      }
      if (this.isType17) {
        return POSITION_FORMULAS.sweepLengthByD({
          dWire: this.formData.d_wire,
          diameter: this.formData.d_outer_1,
          turns: this.formData.number_full_turns
        })
      }
      return ''
    },
    // Шаг
    calcStep () {
      return POSITION_FORMULAS.step({
        dWire: this.formData?.d_wire,
        rt1Id: this.formData?.reference_turn_1?.id,
        rt2Id: this.formData?.reference_turn_2?.id,
        turns: this.formData.number_turns,
        bodyLength: this.formData.body_length
      })
    },
    // Пробники
    calcProbes () {
      return POSITION_FORMULAS.probes(this.formData?.d_wire, this.formData?.value)
    },
    // Перпендикулярность
    calcPerpendicularity () {
      return POSITION_FORMULAS.perpendicularity(this.formData?.body_length)
    },
    // Допуск на шаг
    calcStepLimit () {
      return POSITION_FORMULAS.stepLimit(this.formData?.step, this.formData?.d_wire)
    },
    // Допуск по виткам
    calcTurnsLimit () {
      if (this.isType1) {
        return POSITION_FORMULAS.turnsLimitType1({
          dWire: this.formData?.d_wire,
          turns: this.formData?.number_full_turns
        })
      }
      return POSITION_FORMULAS.turnsLimit(this.turnsForCalc, this.formData?.d_wire)
    },
    turnsForCalc () {
      return this.isType2 || this.isType9 ? this.formData?.number_turns : this.formData?.number_full_turns
    },
    // Допуск на длину отвода
    calcTapLengthLimit1 () {
      if (!this.isType359) return ''
      return POSITION_FORMULAS.tapLengthLimit(this.formData.tap_length_1)
    },
    calcTapLengthLimit2 () {
      if (!this.isType359) return ''
      return POSITION_FORMULAS.tapLengthLimit(this.formData.tap_length_2)
    },
    // Твердость
    calcHardness () {
      if (!this.formData?.material?.hardness) {
        return ''
      }
      return this.formData.material.hardness
    },
    // Поверхностная обработка
    calcSurfaceTreatment () {
      // console.log(this.formData.material?.gost_standard_id)
      if (!this.formData?.material?.id) {
        return null
      }
      const gost = this.$store.getters['orderDetail/getGostByMaterialId'](this.formData.material.id)
      if (gost[0]?.surface_treatment?.id) {
        return this.$store.getters['records/getSurfaceTreatment'](gost[0].surface_treatment.id)
      }

      return null
    },

    // Допуск на зазор (Стопорные кольца)
    calcClearanceTolerance () {
      return POSITION_FORMULAS.clearanceTolerance(this.formData?.d_outer_1_limit)
    },

    // Допуск на зазор (Пружины растяжения)
    calcClearanceTolerance2 () {
      return POSITION_FORMULAS.clearanceTolerance2(this.formData?.d_wire)
    },

    isDisabledForProcessEngineer () {
      return this.isCatalog && this.$store.getters['permission/isProcessEngineer']
    },

    isShape () {
      if (this.isCatalog) {
        if (this.shapeCatalog?.id === 1) return false
        else return true
      } else {
        if (this.formData.shape?.id === 1) return false
        else return true
      }
    }
  },
  watch: {
    calcIndex1: {
      handler (val) {
        Vue.set(this.formData, 'index', val)
        if (!this.formData.shape && this.isType2) this.formData.shape = this.shapeAll[0]
      },
      immediate: true
    },
    calcIndex2: {
      handler (val) {
        Vue.set(this.formData, 'index_2', val)
      },
      immediate: true
    },
    calcStiffness: {
      handler (val) {
        Vue.set(this.formData, 'stiffness', val)
      },
      immediate: true
    },
    calcWeight: {
      handler (val) {
        Vue.set(this.formData, 'weight', val)
      },
      immediate: true
    }
  }
}

export {
  mixinPositionForm
}
