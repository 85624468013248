<template>
  <div class="w-100">
    <p
      v-if="label"
      class="input__label"
      :class="{ disabled }"
    >
      {{ labelText }}
    </p>

    <div class="d-flex gap-xxs input-copy">
      <VSelect
        :name="name"
        :options="options"
        :disabled="disabled"
        :required="required"
        :pattern="pattern"
      >
        <Validation v-if="required" for="required"/>
        <Validation v-if="pattern" for="pattern" :key-text="patternKey"/>
      </VSelect>
      <VSelect
        v-if="!isMobile"
        v-model="modelTooltip"
        :options="options"
        :placeholder="null"
        disabled
      />
    </div>
  </div>
</template>

<script>
import Validation from '@/components/Form/Validation'
import VSelect from '@/components/Form/VSelect'

import { PATTERN_LIST } from '@/const/validation'
import { mapState } from 'vuex'

export default {
  name: 'SelectTooltip',
  components: { Validation, VSelect },
  inject: {
    formData: {
      from: 'formData',
      default: null
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    patternKey: {
      type: String,
      default: ''
    },
    modelTooltip: {
      type: [Object, null]
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),

    pattern () {
      if (!this.patternKey) return null

      return PATTERN_LIST[this.patternKey]
    },
    labelText () {
      return this.required ? this.label + ' *' : this.label
    }
  }
}
</script>
